import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input, InputNumber } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { getEnumList } from '@extensions/utils';
import { serverFetch } from '@src/core/server';

import { ITransactionParams } from '@entities/transaction-params';
import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';
import { IUser } from '@entities/user';

import { OperationType, enumLabel as enumOperationLabel } from '@enums/operation-type';
import { Currency, enumLabel as currencyLabel } from '@enums/currency';
import { IEnumItem } from '@enums/enum-item';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IDepositin {
    userId: string;
    userLogin: string;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const DepositingModal = (props: IDepositin) => {
    const { TextArea } = Input;

    const [form] = Form.useForm();
    const operationType = Form.useWatch<OperationType>('operationType', form);

    const d = useAppDispatch();

    const { api, userLogin, userId, onSave, onCancel } = props;
    const [operationTypes] = useState<Array<IEnumItem>>(getEnumList(OperationType, enumOperationLabel));
    const [currentOperation, setCurrentOperation] = useState<OperationType>();

    const [currencies] = useState<Array<IEnumItem>>(getEnumList(Currency, currencyLabel));
    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        form.setFieldsValue({
            userId: userId,
            currency: Currency.Rub,
        });

        let filter: ICompanyFilter = {
            userId: userId,
        };

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('companies', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения компаний', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setUser(result[0][0]);
                setCompanies(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (user && user.userSettings) {
            form.setFieldValue('commission', user.userSettings.internalTransferCommission);
        }
    }, [user]);

    const onSaveTransaction = (entity: ITransactionParams) => {
        onSave(entity);
    };

    return (
        <Modal
            destroyOnClose={true}
            width={650}
            title={`Внести средства на счет "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            <Form
                colon={false}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ marginTop: 20 }}
                form={form}
                onFinish={onSaveTransaction}
            >
                <Form.Item hidden name='userId'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='currency'>
                    <Select />
                </Form.Item>
                <Form.Item name='operationType' label='Платеж' rules={[{ required: true, message: 'Укажите тип платежа' }]}>
                    <Select
                        allowClear
                        onChange={(value: OperationType) => {
                            setCurrentOperation(value);
                        }}
                        options={operationTypes.map((t) => {
                            return { value: t.value, label: t.label };
                        })}
                    />
                </Form.Item>
                {currentOperation == OperationType.NonCash && (
                    <Form.Item name='companyId' label='ИНН' rules={[{ required: true, message: 'Укажите ИНН' }]}>
                        <Select
                            allowClear
                            options={companies.map((t) => {
                                return { value: t.id, label: `${t.inn} (${t.legalName})` };
                            })}
                        />
                    </Form.Item>
                )}
                <Form.Item label='Сумма' name='preAmount' wrapperCol={{ span: 9 }} rules={[{ required: true, message: 'Укажите сумму' }]}>
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        style={{ width: '100%' }}
                        onChange={(value: number | null) => {
                            if (!value) {
                                form.setFieldValue('amount', value);
                                return;
                            }

                            let commission = form.getFieldValue('commission');
                            if (commission) {
                                var result = value - value * commission * 0.01;
                                form.setFieldValue('amount', result);
                            } else {
                                form.setFieldValue('amount', value);
                            }
                        }}
                        addonAfter={
                            <Select
                                style={{ width: 90 }}
                                defaultValue={Currency.Rub}
                                onChange={(value: Currency) => {
                                    form.setFieldValue('currency', value);
                                }}
                                options={currencies.map((t) => {
                                    return { value: t.value, label: t.label };
                                })}
                            />
                        }
                    />
                </Form.Item>
                <Form.Item label='Комиссия' name='commission'>
                    <InputNumber
                        decimalSeparator=','
                        precision={2}
                        min={0}
                        style={{ width: '50%' }}
                        onChange={(value: number | null) => {
                            let preAmount = form.getFieldValue('preAmount');

                            if (!preAmount || !value) {
                                form.setFieldValue('amount', preAmount);
                            } else {
                                var result = preAmount - preAmount * value * 0.01;
                                form.setFieldValue('amount', result);
                            }
                        }}
                        addonAfter='%'
                    />
                </Form.Item>
                <Form.Item name='amount' label='Итого'>
                    <InputNumber
                        decimalSeparator=','
                        disabled={true}
                        precision={2}
                        min={0}
                        style={{ width: '50%', background: '#f6fefa' }}
                    />
                </Form.Item>
                <Form.Item
                    label='Комментарий'
                    name='comment'
                    rules={[{ required: operationType == OperationType.System, message: 'Укажите комментарий' }]}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DepositingModal;
