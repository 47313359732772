import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, Form, Space, Select, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ICountry } from '@entities/country';
import { IUserSession } from '@entities/user-session';
import { ITradingPlatform } from '@entities/trading-platform';

import { Permission, hasPermission } from '@enums/permission';

const TradingPlatform = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ITradingPlatform>({
        id: undefined,
        countryId: undefined,
        name: undefined,
    });

    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [manageTradingPlatform] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTradingPlatforms));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                serverFetch(`tradingplatforms/${id}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения торговой площадки', ex, () => d(userLoaded(undefined)));
                    });
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                if (id) setEntity(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = () => {
        setLoading(true);

        serverFetch(`tradingplatforms`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения торговой площадки', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader
                title={`${
                    id
                        ? !manageTradingPlatform
                            ? 'Информация о торговой площадке'
                            : 'Изменить торговую площадку'
                        : 'Добавить торговую площадку'
                }`}
            />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        wrapperCol={{ span: 5 }}
                        initialValue={entity.countryId}
                        required
                        label='Страна'
                        name='countryId'
                        rules={[{ required: true, message: 'Выберите страну' }]}
                    >
                        <Select
                            autoFocus
                            showSearch
                            onChange={(value) => setEntity({ ...entity, countryId: value })}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={countries.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        ></Select>
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название торговой площадки' }]}
                    >
                        <Input
                            disabled={!manageTradingPlatform}
                            autoFocus
                            onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase())}
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageTradingPlatform && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default TradingPlatform;
