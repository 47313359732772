import { UnitType } from '../enums/unit-type';

export interface IItem {
    id: string | undefined;
    name: string | undefined;
    code: string | undefined;
    unitType: UnitType | undefined;
    sequence: number | undefined;
}

export const color = (name?: string) => {
    switch (name) {
        case 'Срезка':
            return '#800080';
        case 'Зелень':
            return '#6aa84f';
        case 'Палета (срезка)':
            return '#3d85c6';
        case 'Палета (зелень)':
            return '#1e513c';
        case 'Телега (срезка)':
            return '#dd4b5f';
        case 'Телега (горшечка)':
            return '#794044';
        default:
            return '#FF0000';
    }
};
