export enum AuditLogReferenceType {
    Truck = 1,
    Bill = 2,
    BoxGroup = 3,
    AdditionalLoad = 4,
    TransferBox = 5,
    Transaction = 6,
}

export const enumLabel = (value?: AuditLogReferenceType) => {
    switch (value) {
        case AuditLogReferenceType.Truck:
            return 'Машины';
        case AuditLogReferenceType.Bill:
            return 'Счета';
        case AuditLogReferenceType.BoxGroup:
            return 'Партии';
        case AuditLogReferenceType.AdditionalLoad:
            return 'Догрузы';
        case AuditLogReferenceType.TransferBox:
            return 'Перестановки';
        case AuditLogReferenceType.Transaction:
            return 'Платежные операции';
        default:
            return '';
    }
};
