import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef } from 'antd';
import { Form, InputNumber, Input, Table, Select, Tooltip, Popconfirm } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { IPackageTemplate } from '@entities/package-template';
import { IBillPackage } from '@entities/bill-package';
import { NotificationInstance } from 'antd/es/notification/interface';

type InputRef = GetRef<typeof InputNumber>;
type SelectRef = GetRef<typeof Select>;
type FormInstance<T> = GetRef<typeof Form<T>>;

const { TextArea } = Input;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    ellipsis?: boolean;
    children: React.ReactNode;
    dataIndex: keyof IBillPackage;
    record: IBillPackage;
    options: Array<IPackageTemplate>;
    handleSave: (dataIndex: string, record: IBillPackage) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    ellipsis,
    children,
    dataIndex,
    record,
    options,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);

    const inputQtyRef = useRef<InputRef>(null);
    const inputCommentRef = useRef<InputRef>(null);
    const selectRef = useRef<SelectRef>(null);

    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            if (dataIndex == 'boxQty') {
                const current: any = inputQtyRef.current;
                if (current) current.focus();
            } else if (dataIndex == 'comment') {
                const current: any = inputCommentRef.current;
                if (current) current.focus();
            } else {
                const current: any = selectRef.current;
                if (current) current.focus();
            }
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);

        form.setFieldsValue({ [dataIndex]: record[dataIndex] });

        if (dataIndex == 'templateId') {
            const template: IPackageTemplate | undefined = options && options.find((o) => (o.id = dataIndex));

            if (template) {
                form.setFieldsValue({ ['length']: template.length });
                form.setFieldsValue({ ['width']: template.width });
                form.setFieldsValue({ ['height']: template.height });
            }
        }
    };

    const onSave = async (dataIndex: string) => {
        try {
            const values = await form.validateFields();

            toggleEdit();

            handleSave(dataIndex, { ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: dataIndex == 'boxQty',
                        message: `Укажите ${title}.`,
                    },
                ]}
            >
                <InputNumber
                    style={{ width: 90 }}
                    ref={inputQtyRef}
                    max={record.maxBoxQty}
                    min={1}
                    onPressEnter={() => onSave(dataIndex)}
                    onBlur={() => onSave(dataIndex)}
                />
            </Form.Item>
        ) : (
            <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {childNode}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface IPackagesControl {
    dataSource: Array<IBillPackage>;
    onChange: (e: Array<IBillPackage>) => void;
}

const Packages: React.FC<IPackagesControl> = ({ ...props }) => {
    const { dataSource, onChange } = props;

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        onChange(newData);
    };
    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; options?: Array<IPackageTemplate>; dataIndex: string })[] = [
        {
            dataIndex: 'action',
            width: 40,
            align: 'center',
            render: (_, record: any) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title='Удалить выбранную коробку из списка перестановки?' onConfirm={() => handleDelete(record.key)}>
                        <DeleteFilled style={{ fontSize: 14 }} />
                    </Popconfirm>
                ) : null,
        },
        {
            title: 'Коробка',
            width: 350,
            dataIndex: 'templateName',
        },
        {
            title: 'Кол-во (шт.)',
            dataIndex: 'boxQty',
            width: 120,
            editable: true,
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            width: 320,
            render: (_, record: any) => {
                return <Tooltip title={record?.comment}>{record?.comment}</Tooltip>;
            },
        },
        { dataIndex: 'none' },
    ];

    const handleSave = (dataIndex: string, row: IBillPackage) => {
        const newDataSource = [...dataSource];
        const index = newDataSource.findIndex((item) => row.key === item.key);
        const item = newDataSource[index];

        newDataSource.splice(index, 1, {
            ...item,
            ...row,
        });

        onChange(newDataSource);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: IBillPackage) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                options: col.options,
                handleSave,
            }),
        };
    });

    return (
        <Table
            components={components}
            rowClassName={() => 'editable-row'}
            dataSource={dataSource}
            columns={columns as ColumnTypes}
            pagination={false}
            scroll={{ y: 300 }}
        />
    );
};

export default Packages;
