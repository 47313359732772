import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Button, Rate, Input, Form, Modal, Image, Flex, Spin, notification } from 'antd';

import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { getDateTimeLocal } from '@extensions/utils';

import { userLoaded, setRestartServiceRequired } from '@store/actions';
import { useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { IFeedback } from '@entities/feedback';

dayjs.extend(utc);

const Feedback = () => {
    const { TextArea } = Input;

    const { id } = useParams();
    const navigate = useNavigate();

    const d = useAppDispatch();

    const [modal, modalContextHolder] = Modal.useModal();

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IFeedback>();
    const [images, setImages] = useState<Array<any>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setLoadingImages(true);

        serverFetch(`feedbacks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения отзыва', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });

        serverFetch(`feedbacks/${id}/images`, { method: 'GET' })
            .then((data) => {
                setImages(data);
                setLoadingImages(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения изображений', ex, () => d(userLoaded(undefined)));
                setLoadingImages(false);
            });
    }, []);

    const onClose = () => {
        modal.confirm({
            title: `Закрыть отзыв как решенный?`,
            icon: <QuestionCircleOutlined />,
            okType: 'primary',
            okText: 'ОК',
            cancelText: 'Отмена',
            onOk: () => {
                serverFetch(`feedbacks/close`, { method: 'POST', bodyData: entity })
                    .then(() => {
                        d(setRestartServiceRequired(true));
                        navigate(-1);
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка закрытия отзыва', ex, () => d(userLoaded(undefined)));
                    });
            },
        });
    };

    const renderImages = () => {
        return images.map((img) => {
            return <Image width={150} src={`data:image/jpeg;base64,${img}`} style={{ marginRight: '10px !important' }} />;
        });
    };

    return (
        <>
            <FormHeader title={`Отзыв ${entity ? `"${entity.feedbackNumber}"` : ''}`} />
            {!loading && (
                <>
                    <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 10 }} onFinish={onClose}>
                        <Form.Item label='ID'>
                            <Flex justify='left'>
                                <Form.Item style={{ width: 80, marginRight: 20, marginBottom: 0 }}>
                                    <Input readOnly={true} defaultValue={entity?.consigneeCode} />
                                </Form.Item>

                                <Form.Item label='Маркировка' style={{ width: 'calc(100% - 100px)', marginBottom: 0 }}>
                                    <Input readOnly={true} defaultValue={entity?.markingCode} />
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                        <Form.Item label='ФИО'>
                            <Input readOnly={true} defaultValue={entity?.fullName} />
                        </Form.Item>

                        <Form.Item label='Телефон'>
                            <Input readOnly={true} defaultValue={entity?.phone} />
                        </Form.Item>

                        <Form.Item label='Email'>
                            <Input readOnly={true} defaultValue={entity?.email} />
                        </Form.Item>

                        <Form.Item label='Оценка'>
                            <Rate disabled={true} defaultValue={entity?.rate} />
                        </Form.Item>

                        <Form.Item label='Отзыв'>
                            <TextArea rows={6} readOnly={true} defaultValue={entity?.description} />
                        </Form.Item>

                        <Form.Item label='Изображения'>
                            {loadingImages ? (
                                <Spin tip='Загрузка изображений...' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            ) : images && images.length > 0 ? (
                                <Image.PreviewGroup>{renderImages()}</Image.PreviewGroup>
                            ) : (
                                <span style={{ fontStyle: 'italic' }}>Нет загруженных изображений...</span>
                            )}
                        </Form.Item>

                        <Form.Item label='Страна'>
                            <Flex justify='left'>
                                <Form.Item style={{ width: 150, marginRight: 20, marginBottom: 0 }}>
                                    <Input readOnly={true} defaultValue={entity?.countryName} />
                                </Form.Item>

                                <Form.Item label='Количество' style={{ width: 250, marginRight: 20, marginBottom: 0 }}>
                                    <Input readOnly={true} defaultValue={entity?.boxQty} />
                                </Form.Item>

                                <Form.Item label='AWB' style={{ width: 'calc(100% - 440px)', marginBottom: 0 }}>
                                    <Input readOnly={true} defaultValue={entity?.awbNumber} />
                                </Form.Item>
                            </Flex>
                        </Form.Item>

                        <Form.Item label='Машина'>
                            <Flex justify='left'>
                                <Form.Item style={{ width: 50, marginRight: 20, marginBottom: 0 }}>
                                    <a
                                        href={`${window.location.protocol}//${window.location.host}/logistics/delivery/${entity?.truckId}`}
                                        target='_blank'
                                        style={{ fontWeight: 600 }}
                                    >
                                        {entity?.truckNumber}
                                    </a>
                                </Form.Item>

                                <Form.Item label='Дата отгрузки' style={{ width: 250, marginBottom: 0, marginRight: 20 }}>
                                    <Input readOnly={true} defaultValue={getDateTimeLocal(entity?.departureOn)} />
                                </Form.Item>
                                <Form.Item label='Журнал' style={{ width: 'calc(100% - 440px)', marginBottom: 0 }}>
                                    <a
                                        href={`${window.location.protocol}//${window.location.host}/warehouse/shipmentjournals/${entity?.journalId}`}
                                        target='_blank'
                                        style={{ fontWeight: 600 }}
                                    >
                                        {entity?.journalNumber}
                                    </a>
                                </Form.Item>
                            </Flex>
                        </Form.Item>

                        {entity && entity.rate && entity.rate < 5 && (
                            <>
                                <Form.Item
                                    initialValue={entity.solution}
                                    name='solution'
                                    label='Решение проблемы'
                                    required={entity.rate < 4 && !entity.closedOn}
                                    rules={[{ required: entity.rate < 4, message: 'Укажите решение проблемы' }]}
                                >
                                    <TextArea
                                        style={{ backgroundColor: '#e6f0d4' }}
                                        rows={6}
                                        readOnly={!!entity.closedOn}
                                        onChange={(data) => {
                                            setEntity({ ...entity, solution: data.target.value });
                                        }}
                                    />
                                </Form.Item>

                                {!entity.closedOn && (
                                    <Form.Item wrapperCol={{ span: 13 }}>
                                        <Button type='primary' htmlType='submit' loading={loading} style={{ float: 'right' }}>
                                            Решено
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form>
                </>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Feedback;
