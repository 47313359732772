import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Button, Input, Select, Form, Space } from 'antd';

import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';
import { getEnumList } from '@extensions/utils';

import { IUserSession } from '@entities/user-session';
import { IContact } from '@entities/contact';
import { IWarehouse } from '@entities/warehouse';

import { IEnumItem } from '@enums/enum-item';
import { Permission, hasPermission } from '@enums/permission';
import { ContactType, enumLabel as contactEnumLabel } from '@enums/contact-type';

const Contact = () => {
    const [form] = Form.useForm();

    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [types] = useState<Array<IEnumItem>>(getEnumList(ContactType, contactEnumLabel));
    const [currentType, setCurrentType] = useState<ContactType>();

    const [entity, setEntity] = useState<IContact>();
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [manageCity] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageCities));

    const [phone, setPhone] = useState<string>();

    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageContacts));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`contacts/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения контакта', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0]);

                let user = result[0][1];
                if (user) {
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        phone: undefined,
                        workingPosition: undefined,
                    });
                }

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = (contact: IContact) => {
        if (!contact) return;

        setLoading(true);

        contact.phone = phone;

        serverFetch(`contacts`, { method: id ? 'PUT' : 'POST', bodyData: contact })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения контакта', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (!manageCity ? 'Информация о контакте' : 'Изменить контакт') : 'Добавить контакт'}`} />
            {entity && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 6 }} form={form} onFinish={onSave}>
                    <Form.Item initialValue={entity.id} name='id' hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item initialValue={entity.type} label='Тип' name='type'>
                        <Select
                            key='type'
                            allowClear
                            onChange={(value) => {
                                setCurrentType(value);
                                form.setFieldValue('workingPosition', contactEnumLabel(value));
                            }} 
                            options={types.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.workingPosition}
                        required
                        label='Должность'
                        name='workingPosition'
                        rules={[{ required: entity.type != ContactType.SystemSupport, message: 'Укажите должность' }]}
                    >
                        <Input disabled={isViewOnly || currentType == ContactType.SystemSupport} />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.phone}
                        required
                        name='phone'
                        label='Телефон'
                        rules={[{ required: true, message: 'Укажите телефон' }]}
                    >
                        <MaskedInput
                            disabled={isViewOnly}
                            size='middle'
                            mask={'+0 (000) 000-00-00'}
                            onChange={(data) => {
                                setPhone(data.maskedValue);
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.warehouseId} label='Склад' name='warehouseId'>
                        <Select
                            allowClear
                            disabled={isViewOnly}
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={warehouses.map((w) => {
                                return { value: w.id, label: `${w.code} (${w.cityName})` };
                            })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.fullName} label='ФИО' name='fullName'>
                        <Input disabled={isViewOnly || currentType == ContactType.SystemSupport} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3, span: 6 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageCity && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Contact;
