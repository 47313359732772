import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input, InputNumber, Flex } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';

import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import Account from '@controls/account';

import { toFinanceString } from '@extensions/utils';
import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserAccount } from '@entities/user-account';
import { IUser } from '@entities/user';
import { UserAccountType } from '@src/core/enums/user-account-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITransfer {
    userId: string;
    userLogin: string;
    accounts: Array<IUserAccount>;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const RefundModal = (props: ITransfer) => {
    const { TextArea } = Input;

    const d = useAppDispatch();

    const [form] = Form.useForm();

    const { userLogin, userId, accounts, onSave, onCancel, api } = props;

    const [user, setUser] = useState<IUser>();
    const [fromAccounts] = useState<Array<IUserAccount>>(accounts);
    const [toAccounts, setToAccounts] = useState<Array<IUserAccount>>([]);
    const [canSetToAccount, setCanSetToAccount] = useState<boolean>(false);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            const promises = [
                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setUser(result[0][0]);

                form.setFieldsValue({
                    userId: userId,
                });

                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onSaveTransaction = (entity: ITransactionParams) => {
        if (!entity.amount) return;

        let fromAccount = accounts.find((a) => a.id == entity.fromUserAccountId);
        entity.currency = fromAccount?.currency;

        onSave(entity);
    };

    return (
        <Modal
            destroyOnClose={true}
            width={550}
            title={`Возврат денежных средств "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            {user && (
                <Form
                    colon={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ marginTop: 20 }}
                    form={form}
                    onFinish={onSaveTransaction}
                >
                    <Form.Item hidden name='userId'>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name='fromUserAccountId'
                        label='Счет cписания'
                        rules={[{ required: true, message: 'Укажите счет для списания' }]}
                    >
                        <Select
                            onChange={(value: string) => {
                                let currentAccount = accounts.find((a) => a.id == value);

                                if (currentAccount && currentAccount.type != UserAccountType.User) {
                                    let toAccount = accounts.find(
                                        (a) => a.type == UserAccountType.User && a.currency == currentAccount?.currency
                                    );

                                    if (toAccount) {
                                        form.setFieldValue('userAccountId', toAccount.id);
                                        setToAccounts([toAccount]);
                                        setCanSetToAccount(true);
                                    } else {
                                        setToAccounts([]);
                                        setCanSetToAccount(false);
                                        form.setFieldValue('userAccountId', undefined);
                                    }
                                } else {
                                    setToAccounts([]);
                                    setCanSetToAccount(false);
                                    form.setFieldValue('userAccountId', undefined);
                                }
                            }}
                            options={fromAccounts.map((a) => {
                                return {
                                    value: a.id,
                                    label: (
                                        <Flex align='center' gap='small'>
                                            <Account type={a.type} currency={a.currency} />
                                            <span style={{}}>{toFinanceString(a.amount || 0, 2)}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item name='userAccountId' label='Счет пополнения'>
                        <Select
                            disabled={!canSetToAccount}
                            options={toAccounts.map((a) => {
                                return {
                                    value: a.id,
                                    label: (
                                        <Flex align='center' gap='small'>
                                            <Account type={a.type} currency={a.currency} />
                                            <span style={{}}>{toFinanceString(a.amount || 0, 2)}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item label='Сумма' name='amount' wrapperCol={{ span: 9 }} rules={[{ required: true, message: 'Укажите сумму' }]}>
                        <InputNumber
                            precision={2}
                            decimalSeparator=','
                            min={0}
                            style={{ width: '100%' }}
                            onChange={(value: number | null) => {
                                form.setFieldValue('amount', value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Комментарий' name='comment' rules={[{ required: true, message: 'Укажите комментарий' }]}>
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default RefundModal;
