import React, { useState, useEffect } from 'react';

import { v4 as uuid } from 'uuid';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Modal, Tooltip, Flex } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { groupBy, toFinanceString, getDate } from '@extensions/utils';

import { IBill } from '@entities/bill';
import { UnitType } from '@enums/unit-type';
import { Currency, enumSign as currencySign } from '@enums/currency';

import { PenIcon } from '@src/core/icons';

import './bill.css';
import { BillStatus } from '@src/core/enums/bill-status';

dayjs.extend(utc);

interface IBillModal {
    bills: Array<IBill>;
    onClose: () => void;
}

const Bill = (props: IBillModal) => {
    const { bills, onClose } = props;

    const [totalPrice, settotalPrice] = useState<number | undefined>();
    const [totalPriceRub, settotalPriceRub] = useState<number | undefined>();

    const [countryGroups, setGroups] = useState<Record<any, IBill[]>>();

    useEffect(() => {
        settotalPrice(undefined);

        let result: number = 0;
        let resultRub: number = 0;
        bills.map((b) => {
            result += b.totalPriceFinal || b.totalPrice || 0;
            resultRub += b.totalPriceFinalRub || b.totalPriceRub || 0;
        });

        if (result > 0) settotalPrice(result);
        if (resultRub > 0) settotalPriceRub(resultRub);

        setGroups(groupBy(bills, (b) => b.countryName || ''));
    }, [bills]);

    const renderImportTable = () => {
        return (
            countryGroups &&
            Object.entries(countryGroups).map(([countryName, countryValues]) => {
                const markingGroups = groupBy(countryValues, (v) => v.markingCode || '');

                return Object.entries(markingGroups).map(([markingCode, markingValues]) => {
                    const cityName = markingValues[0].cityName;
                    const loadingOn = markingValues[0].loadingOn;
                    const isChanged = markingValues[0].isChanged;
                    const changedOn = markingValues[0].changedOn;
                    const billOn = markingValues[0].billOn;
                    const boxGroupId = markingValues[0].boxGroupId;

                    const untiTypeGroups = groupBy(markingValues, (v) => v.unitType || 0);

                    return Object.entries(untiTypeGroups).map(([unitType, items]) => {
                        return boxGroupId ? (
                            <div className='bill' style={{ marginBottom: 20 }} key={uuid()}>
                                <div className='bill-header'>
                                    <div className='bill-header-item-start'>Страна: {countryName}</div>
                                    <div className='bill-header-item-end'>
                                        Отгрузка: {getDate(loadingOn)}
                                        {isChanged && (
                                            <Tooltip title={`Счет изменен ${getDate(changedOn)}`}>
                                                <PenIcon style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                                <div className='bill-sub-header'>
                                    <span className='bill-header-item-start'>Маркировка: {markingCode}</span>
                                    <span className='bill-header-item-end'>
                                        Доставка: {countryName} - {cityName}
                                    </span>
                                </div>
                                {+unitType == UnitType.Weight
                                    ? renderWeightBillTable(items)
                                    : +unitType == UnitType.Cart
                                    ? renderCartBillTable(items)
                                    : renderPalletBillTable(items)}
                            </div>
                        ) : (
                            <div className='bill' style={{ marginBottom: 20 }} key={uuid()}>
                                <div className='bill-header'>
                                    <div className='bill-header-item-start'>Дата: {getDate(billOn)}</div>
                                </div>
                                {renderOtherBillTable(items)}
                            </div>
                        );
                    });
                });
            })
        );
    };

    const renderCartBillTable = (items: Array<IBill>) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Тариф за телегу</th>
                        <th>Количество, шт</th>
                        <th colSpan={2}>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                <tr key={uuid()}>
                                    <td>
                                        {toFinanceString(b.truckPrice)} <b>{currencySign(Currency.Usd)}</b>
                                    </td>
                                    <td>{b.adjustmentQty}</td>
                                    <td>
                                        {toFinanceString(b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                    </td>
                                    {/*  <td>
                                        {toFinanceString(b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>

                                <tr key={uuid()}>
                                    <td colSpan={2} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700 }}>
                                        <Flex align='center' justify='center' gap='small'>
                                            {b.status == BillStatus.Paid && (
                                                <Tooltip title='Оплачен' color='var(--main-green)'>
                                                    <CheckOutlined style={{ fontSize: 18, color: 'var(--main-green)' }} />
                                                </Tooltip>
                                            )}
                                            {toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                        </Flex>
                                    </td>
                                    {/*  <td style={{ fontWeight: 700 }}>
                                        {toFinanceString(b.totalPriceFinalRub || b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderPalletBillTable = (items: Array<IBill>) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Тариф за паллету</th>
                        <th>Наименование | На паллете, шт</th>
                        <th>Тариф за коробку</th>
                        <th>Коробок, шт</th>
                        <th colSpan={2}>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                {b.packages && b.packages.length > 0 ? (
                                    b.packages.map((p) => {
                                        const index = b.packages?.indexOf(p);
                                        return (
                                            <tr key={uuid()}>
                                                <td>
                                                    {b.truckPrice} <b>{currencySign(Currency.Usd)}</b>
                                                </td>
                                                <td>
                                                    {p.templateName} | {p.qtyPerPallet}
                                                </td>
                                                <td>
                                                    {toFinanceString((b.truckPrice || 0) / p.qtyPerPallet, 2)}{' '}
                                                    <b>{currencySign(Currency.Usd)}</b>
                                                </td>
                                                <td>{p.boxQty}</td>
                                                {index === 0 && (
                                                    <>
                                                        <td rowSpan={b.packages?.length}>
                                                            {toFinanceString(b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                                        </td>
                                                        {/*  <td rowSpan={b.packages?.length}>
                                                            {toFinanceString(b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                                        </td> */}
                                                    </>
                                                )}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr key={uuid()}>
                                        <td>
                                            {b.truckPrice} <b>{currencySign(Currency.Usd)}</b>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{b.adjustmentQty}</td>
                                        <td>
                                            {toFinanceString(b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                        </td>
                                        {/* <td>
                                            {toFinanceString(b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                        </td> */}
                                    </tr>
                                )}
                                <tr key={uuid()}>
                                    <td colSpan={4} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700 }}>
                                        <Flex align='center' justify='center' gap='small'>
                                            {b.status == BillStatus.Paid && (
                                                <Tooltip title='Оплачен' color='var(--main-green)'>
                                                    <CheckOutlined style={{ fontSize: 18, color: 'var(--main-green)' }} />
                                                </Tooltip>
                                            )}
                                            {toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                        </Flex>
                                    </td>
                                    {/* <td style={{ fontWeight: 700 }}>
                                        {toFinanceString(b.totalPriceFinalRub || b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderWeightBillTable = (items: Array<IBill>) => {
        return (
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>AWB</th>
                        <th>Количество</th>
                        <th>Объем</th>
                        <th>Доставка</th>
                        <th colSpan={2}>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                <tr key={uuid()}>
                                    <td>{b.awbNumber}</td>
                                    <td>{b.qty}</td>
                                    <td>{b.volumeWeight}</td>
                                    <td>
                                        {toFinanceString((b.flightPrice || 0) + (b.truckPrice || 0))}
                                        <b style={{ marginLeft: 5 }}>{currencySign(Currency.Usd)}</b>
                                    </td>
                                    <td>
                                        {toFinanceString(b.totalPrice)} <b>{currencySign(Currency.Usd)}</b>
                                    </td>
                                    {/*   <td>
                                        {toFinanceString(b.totalPriceRub)} <b>{currencySign(Currency.Rub)}</b>
                                    </td> */}
                                </tr>
                                <tr key={uuid()}>
                                    <td colSpan={4} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700 }}>
                                        <Flex align='center' justify='center' gap='small'>
                                            {b.status == BillStatus.Paid && (
                                                <Tooltip title='Оплачен' color='var(--main-green)'>
                                                    <CheckOutlined style={{ fontSize: 18, color: 'var(--main-green)' }} />
                                                </Tooltip>
                                            )}
                                            {toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                        </Flex>
                                    </td>
                                    {/*     <td style={{ fontWeight: 700 }}>
                                        {toFinanceString(b.totalPriceFinalRub || b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderOtherBillTable = (items: Array<IBill>) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>Наименование</th>
                        <th colSpan={2}>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                <tr key={uuid()}>
                                    <td colSpan={2} style={{ textAlign: 'left' }}>
                                        {b.comment}
                                    </td>
                                    <td>
                                        {toFinanceString(b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                    </td>
                                    {/*  <td>
                                        {toFinanceString(b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>

                                <tr key={uuid()}>
                                    <td colSpan={2} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700, width: 120 }}>
                                        <Flex align='center' justify='center' gap='small'>
                                            {b.status == BillStatus.Paid && (
                                                <Tooltip title='Оплачен' color='var(--main-green)'>
                                                    <CheckOutlined style={{ fontSize: 18, color: 'var(--main-green)' }} />
                                                </Tooltip>
                                            )}
                                            {toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} {currencySign(Currency.Usd)}
                                        </Flex>
                                    </td>
                                    {/*   <td style={{ fontWeight: 700, width: 150 }}>
                                        {toFinanceString(b.totalPriceFinalRub || b.totalPriceRub, 2)} {currencySign(Currency.Rub)}
                                    </td> */}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <Modal
            width={950}
            title={`Счет на сумму ${totalPrice ? toFinanceString(totalPrice, 2) : toFinanceString(0, 2)}${currencySign(Currency.Usd)} ${
                totalPriceRub ? `( ${toFinanceString(totalPriceRub, 2)}${currencySign(Currency.Rub)} )` : ''
            }`}
            open={true}
            onCancel={() => onClose()}
            cancelText='Закрыть'
            okButtonProps={{ style: { display: 'none' } }}
        >
            {renderImportTable()}
        </Modal>
    );
};

export default Bill;
