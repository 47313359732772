import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Space, Modal, Button, Col, Row, Select, Input, InputNumber, Form, Flex, Divider, Tooltip } from 'antd';

import { PlusOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { v4 as uuid } from 'uuid';

import FormHeader from '@controls/form-header/form-header';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';

import { exception, warning, error } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IBoxGroup } from '@entities/box-group';
import { ICountry } from '@entities/country';
import { IMarking } from '@entities/marking';
import { ICountryItem } from '@entities/country-item';
import { IConsignment } from '@entities/consignment';

const ExtraConsignment = () => {
    const [modal, modalContextHolder] = Modal.useModal();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();

    const { TextArea } = Input;

    const { consignmentId } = useParams();

    const [form] = Form.useForm();

    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);
    const [entity, setEntity] = useState<IConsignment>({ id: undefined, markingId: undefined, boxGroups: [] });
    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageWarehouse));
    const [countryItems, setCountryItems] = useState<Array<ICountryItem>>([]);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries/items', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (consignmentId) {
                promises.push(
                    await serverFetch(`consignments/${consignmentId}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения партии', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setCountryItems(result[0][1]);
                setMarkings(result[0][2]);

                let entity = result[0][3] || { boxGroups: [] };

                var boxGroups: any = [];
                entity.boxGroups.map((b: IBoxGroup) => {
                    if (b.countryId) boxGroups[b.countryId] = { qty: b.qty, volumeWeight: b.volumeWeight };
                });

                entity = {
                    ...entity,
                    ...boxGroups,
                };

                if (consignmentId) {
                    onMarkingChanged(entity.markingId);
                }

                setEntity(entity);
                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onMarkingChanged = async (value: string) => {
        form.setFieldsValue({
            markingId: value,
        });
    };

    const onFinish = (data: any) => {
        let result = { ...entity, ...data };

        let filledBoxGroups = entity.boxGroups?.filter((bg) => bg.qty && bg.qty > 0);
        if (!filledBoxGroups || filledBoxGroups.length <= 0) {
            warning(api, 'Хотя бы одна партия должна быть заполнена');
            return;
        }

        const duplicate = filledBoxGroups.find((bg) => {
            const duplicate = filledBoxGroups?.find((d) => d.key != bg.key && d.countryId == bg.countryId && d.itemId == bg.itemId);
            return !!duplicate;
        });

        if (duplicate) {
            error(api, `Удалите дублирующиеся позиции в разделе "${duplicate.countryName}"`);
            return;
        }

        filledBoxGroups.map((bg) => {
            bg.cityId = data.cityId;
        });

        setLoading(true);

        serverFetch(`consignments/extra`, { method: consignmentId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения партии', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddBoxGroup = (countryId: string | undefined) => {
        if (!countryId) return;

        let country = countries.find((c) => c.id == countryId);
        if (!country) return;

        let groups = [...(entity.boxGroups || [])];

        groups.push({
            key: uuid(),
            id: undefined,
            consignmentId: consignmentId,
            countryId: countryId,
            countryName: country.name,
            countryCode: country.code,
            itemId: undefined,
            qty: undefined,
            calcVolume: undefined,
            loadingOn: undefined,
            awbNumber: undefined,
        });

        setEntity({ ...entity, boxGroups: groups });
    };

    const onDeleteBoxGroup = (key: string | undefined) => {
        if (!key) return;

        let groups = [...(entity.boxGroups || [])];
        groups = groups.filter((g) => g.key != key);

        setEntity({ ...entity, boxGroups: groups });
    };

    const renderBoxGroupsForm = () => {
        let groups = [...(entity.boxGroups || [])];
        return countries.map((c) => {
            let countryGroups = groups.filter((g) => g.countryId == c.id);
            return (
                <Form.Item key={c.id}>
                    <Divider orientation='left' style={{ marginTop: 0 }}>
                        <Tooltip title='Добавить партию' placement='topLeft'>
                            <Button
                                size='small'
                                icon={<PlusOutlined />}
                                style={{ margin: '4px 15px 2px -5px' }}
                                onClick={() => {
                                    onAddBoxGroup(c.id);
                                }}
                            />
                            {c.name}
                        </Tooltip>
                    </Divider>

                    {countryGroups.map((bg: IBoxGroup) => {
                        return (
                            <Form.Item key={bg.key} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <Flex gap='small' align='center'>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                                modal.confirm({
                                                    title: `Удалить выбранную партию?`,
                                                    icon: <QuestionCircleOutlined />,
                                                    okType: 'primary',
                                                    okText: 'ОК',
                                                    cancelText: 'Отмена',
                                                    onOk: () => {
                                                        onDeleteBoxGroup(bg.key);
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={`${bg.key}itemId`}
                                        initialValue={bg.itemId}
                                        style={{ width: 160, minWidth: 160, marginBottom: 0 }}
                                        rules={[{ required: true, message: 'Укажите продукт' }]}
                                    >
                                        <Select
                                            placeholder='Продукт'
                                            disabled={isViewOnly}
                                            onChange={(value) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.itemId = value;
                                                }
                                            }}
                                            filterOption={(input, option) =>
                                                (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                            }
                                            options={countryItems
                                                .filter((i) => i.countryId == bg.countryId)
                                                .map((i) => {
                                                    return { value: i.itemId, label: i.itemName };
                                                })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.qty}
                                        name={`${bg.key}qty`}
                                        style={{ width: 140, minWidth: 140, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            placeholder='Количество'
                                            disabled={isViewOnly}
                                            addonAfter='шт'
                                            min={0}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.qty = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.grossWeight}
                                        name={`${bg.key}grossWeight`}
                                        style={{ width: 170, minWidth: 170, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            min={0}
                                            placeholder='Брутто'
                                            disabled={isViewOnly}
                                            stringMode
                                            step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                            addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.grossWeight = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.volumeWeight}
                                        name={`${bg.key}volumeWeight`}
                                        style={{ width: 170, minWidth: 170, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            placeholder='Объемный'
                                            min={0}
                                            disabled={isViewOnly}
                                            stringMode
                                            step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                            addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.volumeWeight = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Flex>
                            </Form.Item>
                        );
                    })}
                </Form.Item>
            );
        });
    };

    return (
        <>
            <Row>
                <FormHeader title={`${consignmentId ? (isViewOnly ? 'Информация о партии' : 'Изменить') : 'Добавить партию'}`} />
            </Row>
            {!loading && (
                <Row>
                    <Col span={24}>
                        <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish}>
                            <Form.Item
                                initialValue={entity.markingId}
                                required
                                label='Маркировка'
                                name='markingId'
                                rules={[{ required: true, message: 'Выберите маркировку' }]}
                            >
                                <Select
                                    disabled={isViewOnly}
                                    autoFocus
                                    showSearch
                                    onChange={(value) => onMarkingChanged(value)}
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={markings.map((m) => {
                                        return { value: m.id, label: `${m.code} / ${m.cityName}` };
                                    })}
                                ></Select>
                            </Form.Item>
                            <Form.Item initialValue={entity.comment} label='Комментарий' name='comment'>
                                <TextArea
                                    disabled={isViewOnly}
                                    rows={3}
                                    onChange={(data) => {
                                        setEntity({ ...entity, comment: data.target.value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item style={{ marginTop: 30, marginBottom: 0 }} wrapperCol={{ offset: 1, span: 22 }}>
                                {renderBoxGroupsForm()}
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 23 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='text' onClick={() => navigate(-1)}>
                                        Закрыть
                                    </Button>
                                    {!isViewOnly && (
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Сохранить
                                        </Button>
                                    )}
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default ExtraConsignment;
