export enum EmailOutboxStatus {
    Created = 10,
    SendFailed = 20,
    Sent = 30,
}

export const enumLabel = (value?: EmailOutboxStatus) => {
    switch (value) {
        case EmailOutboxStatus.Created:
            return 'Создано';
        case EmailOutboxStatus.SendFailed:
            return 'Ошибка отправки';
        case EmailOutboxStatus.Sent:
            return 'Отправлено';
        default:
            return '';
    }
};
