import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isMobile } from 'react-device-detect';

import { Divider, Row, Col, notification, Flex, Tabs } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import Employees from '@controls/users/employees';
import Companies from '@controls/users/companies';
import Discounts from '@controls/users/discounts';
import Debts from '@controls/users/debts';
import Account from '@controls/account';

import UserFormMobile from '@components/users/user-form-mobile';
import UserFormWeb from '@components/users/user-form-web';

import { getEnumList } from '@extensions/utils';

import { exception, success } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@entities/user-permission';
import { IWarehouse } from '@entities/warehouse';
import { IUserDeliverySetting } from '@entities/user-delivery-setting';
import { IUserAccount } from '@entities/user-account';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';

import { UserType, enumLabel as userTypeLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { DayOfWeek, enumLabel as dayOfWeekLabel } from '@enums/day-of-week';
import { IEnumItem } from '@enums/enum-item';

interface IUserParams {
    user: IUser;
    userPermissions: Array<IUserPermission>;
    deliverySetting: IUserDeliverySetting;
}

interface IDay {
    label: string;
    type: DayOfWeek;
    checked: boolean;
}

const User = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageUsers));

    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [days, setDays] = useState<Array<IDay>>([]);

    const [accounts, setAccounts] = useState<Array<IUserAccount>>([]);

    const [loadedUserPermissions, setLoadedUserPermissions] = useState<Array<IUserPermission>>();
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));
    const [dayOfWeeks] = useState<Array<IEnumItem>>(getEnumList(DayOfWeek, dayOfWeekLabel));
    const [phone, setPhone] = useState<string>();
    const [userDebtsRefreshRequired, setUserDebtsRefreshRequired] = useState<boolean>();

    const [userTypes] = useState<Array<IEnumItem>>(
        getEnumList(UserType, userTypeLabel).filter((t) => !!id || t.value == UserType.System || t.value == UserType.Supplier)
    );

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`users/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/permissions`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения прав', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/deliverysetting`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения настроек доставки', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/accounts`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0] || []);

                let user = result[0][1];
                if (user) {
                    
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        login: undefined,
                        email: undefined,
                        fullName: undefined,
                        isActive: undefined,
                        phone: undefined,
                        isArchived: false,
                        type: UserType.System,
                        allowEmailNotification: true,
                        userSettings: {
                            useInternalExchangeRate: false,
                            showBills: false,
                            showNotifications: false,
                        },
                    });
                }

                if (user && user.id && user.type != UserType.System) {
                    setUserDebtsRefreshRequired(true);
                }

                let userPermissions = result[0][2];

                if (userPermissions) {
                    setLoadedUserPermissions(userPermissions);
                } else {
                    setLoadedUserPermissions([]);
                }

                let deliverySetting: IUserDeliverySetting = result[0][3];
                let selectedDays: Array<DayOfWeek> = [];

                if (deliverySetting) {
                    selectedDays = JSON.parse(deliverySetting.daysOfWeek);
                }

                let tmpDays: Array<IDay> = [];
                dayOfWeeks.map((d) => {
                    let selectedDay = selectedDays.find((s) => s == d.value);

                    let day: IDay = {
                        type: d.value,
                        label: d.label,
                        checked: selectedDay != undefined,
                    };

                    tmpDays.push(day);
                });

                setDays(tmpDays);

                setAccounts(result[0][4]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!userDebtsRefreshRequired) return;

        setUserDebts([]);

        setUserDebtsRefreshRequired(false);

        var userDebtFilter: IUserDebtFilter = {
            userId: entity?.id,
            isArchived: false,
        };

        serverFetch(`userdebts`, { method: 'GET', queryParams: userDebtFilter })
            .then((data) => {
                setUserDebts(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    }, [userDebtsRefreshRequired]);

    useEffect(() => {
        if (loadedUserPermissions) {
            let entities: Array<IUserPermission> = [];
            permissions.map((p) => {
                let permission = loadedUserPermissions.find((e) => e.permissionCode === p.value);
                if (permission) {
                    entities.push({ userId: entity?.id as string, name: p.label, permissionCode: p.value, isActive: true });
                } else {
                    entities.push({ userId: entity?.id as string, name: p.label, permissionCode: p.value, isActive: false });
                }
            });

            setUserPermissions(entities);
        }
    }, [loadedUserPermissions]);

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        let activeUserPermissions = userPermissions.filter((p) => p.isActive);

        let activeDays = days.filter((p) => p.checked).map((d) => d.type);

        let deliverySetting: IUserDeliverySetting = {
            userId: entity.id,
            daysOfWeek: JSON.stringify(activeDays),
        };

        entity.phone = phone;

        let data: IUserParams = {
            user: entity,
            userPermissions: activeUserPermissions,
            deliverySetting: deliverySetting,
        };

        serverFetch(`users`, { method: entity.id ? 'PUT' : 'POST', bodyData: data })
            .then((data) => {
                if (!entity.id) setEntity({ ...entity, id: data });

                setLoading(false);
                success(api, 'Изменения успешно сохранены');
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRestore = () => {
        serverFetch(`users/restore/${entity?.id}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка восстановления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderAccounts = () => {
        return (
            accounts &&
            accounts.length > 0 && (
                <div>
                    <Divider orientation='left'>Cчета</Divider>

                    <Flex justify='flex-start' align='center' gap='large'>
                        {accounts.map((a) => {
                            return (
                                <Account
                                    key={`${a.type}${a.currency}`}
                                    type={a.type}
                                    currency={a.currency}
                                    amount={a.amount}
                                    size='middle'
                                />
                            );
                        })}
                    </Flex>
                </div>
            )
        );
    };

    let tabItems = [
        {
            label: `Основное`,
            key: 'main',
            children: isMobile ? (
                <UserFormMobile
                    user={entity}
                    userPermissions={userPermissions}
                    days={days}
                    onFinish={() => onFinish()}
                    onRestore={() => onRestore()}
                    onChange={(e: IUser) => setEntity(e)}
                    onChangePhone={(e: string) => setPhone(e)}
                    onChangeDays={(e: Array<IDay>) => setDays(e)}
                    onChangeUserPermissions={(e: Array<IUserPermission>) => setUserPermissions(e)}
                    onClose={() => navigate(-1)}
                    isViewOnly={isViewOnly}
                    api={api}
                    warehouses={warehouses}
                    loading={loading}
                    userTypes={userTypes}
                />
            ) : (
                <UserFormWeb
                    user={entity}
                    userPermissions={userPermissions}
                    days={days}
                    onFinish={() => onFinish()}
                    onRestore={() => onRestore()}
                    onChange={(e: IUser) => setEntity(e)}
                    onChangePhone={(e: string) => setPhone(e)}
                    onChangeDays={(e: Array<IDay>) => setDays(e)}
                    onChangeUserPermissions={(e: Array<IUserPermission>) => setUserPermissions(e)}
                    onClose={() => navigate(-1)}
                    isViewOnly={isViewOnly}
                    api={api}
                    warehouses={warehouses}
                    loading={loading}
                    userTypes={userTypes}
                />
            ),
        },
    ];

    if (entity?.id && (entity?.type === UserType.MainClient || entity?.type === UserType.Supplier)) {
        tabItems.push({
            label: `Финансы`,
            key: 'finance',
            children: (
                <Row>
                    <Col span={12}>
                        {renderAccounts()}
                        <Discounts userId={entity.id} userDebts={userDebts} isViewOnly={isViewOnly} />
                    </Col>
                    <Col offset={1} span={11}>
                        <Debts
                            userId={entity.id}
                            userDebts={userDebts}
                            isViewOnly={isViewOnly}
                            onRefresh={() => setUserDebtsRefreshRequired(true)}
                        />
                    </Col>
                </Row>
            ),
        });

        tabItems.push({
            label: `Контрагенты`,
            key: 'companies',
            children: <Companies userId={entity.id} isViewOnly={isViewOnly} />,
        });

        tabItems.push({
            label: `Сотрудники`,
            key: 'employees',
            children: <Employees userId={entity.id} userLogin={entity.login} isViewOnly={isViewOnly} />,
        });
    }

    return (
        <>
            <FormHeader
                title={`${
                    entity?.id
                        ? isViewOnly
                            ? `Информация о пользователе "${entity.login}"`
                            : `Изменить пользователя "${entity.login}"`
                        : 'Добавить пользователя'
                }`}
            />
            <Tabs size='large' items={tabItems} />

            {contextHolder}
        </>
    );
};

export default User;
