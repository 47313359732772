import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { Dayjs } from 'dayjs';

import { Table, Input, DatePicker, Modal, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    EditOutlined,
    PlusOutlined,
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    DeleteOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { delayAction, toFinanceString, getDate } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IWaybill } from '@entities/waybill';
import { IWaybillFilter } from '@entities/waybill-filter';
import { IUserSession } from '@entities/user-session';

import { WaybillType } from '@enums/waybill-type';
import { Currency, enumSign as currencySign } from '@enums/currency';
import { Permission, hasPermission } from '@enums/permission';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Waybills';

const Waybills = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const initFilter: IWaybillFilter = { type: WaybillType.AWB };

    const { RangePicker } = DatePicker;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const [waybills, setWaybills] = useState<Array<IWaybill>>([]);
    const [currentWaybill, setCurrentWaybill] = useState<IWaybill>();

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const filter = useAppSelector<IWaybillFilter>((s) => s.filters[filterContext]);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            setWaybills([]);
            setSelectedIds([]);
            setCurrentWaybill(undefined);

            const promises = [
                await serverFetch('waybills', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения накладных', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWaybills(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onDelete = () => {
        if (!currentWaybill) return;

        serverFetch(`waybills/${currentWaybill.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления накладной', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWaybill)) {
                                securityRestriction(api, [Permission.ManageItems]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageWaybill) ? 'Изменить' : 'Информация о продукте'}`,
                        key: 'edit',
                        disabled: !currentWaybill,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentWaybill?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentWaybill,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWaybill)) {
                                securityRestriction(api, [Permission.ManageWaybill]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить продукт "${currentWaybill?.number}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = waybills.find((o) => o.id === selectedRowKeys[0]);
            setCurrentWaybill(entity);
        } else {
            setCurrentWaybill(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 250 }}
                            key='number'
                            placeholder='Номер'
                            value={filter?.number}
                            onChange={(data) => {
                                d(setFilter({ ...filter, number: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 220 }}
                            allowEmpty={[true, true]}
                            key='loading'
                            format='DD.MM.YYYY'
                            placeholder={['Дата с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[filter?.dateFrom ? dayjs(filter?.dateFrom) : null, filter?.dateTo ? dayjs(filter?.dateTo) : null]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                dateFrom: undefined,
                                                dateTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            dateFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            dateTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IWaybill> = [
            {
                title: 'Дата',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return getDate(record.dateOn);
                },
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 250,
            },
            {
                title: 'Кол-во',
                dataIndex: 'qty',
                align: 'center',
                width: 100,
            },
            {
                title: 'Вес Брутто',
                width: 150,
                align: 'center',
                render: (_: any, record) => {
                    return (
                        <>
                            {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                        </>
                    );
                },
            },
            {
                title: 'Объемный Вес',
                align: 'center',
                width: 150,
                render: (_: any, record) => {
                    return (
                        <>
                            {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                        </>
                    );
                },
            },
            {
                title: `Стоимость (${currencySign(Currency.Usd)})`,
                align: 'center',
                width: 200,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return toFinanceString(record.amount ?? 0, 2);
                },
            },
            {},
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={waybills}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Waybills;
