import React from 'react';
import { v4 as uuid } from 'uuid';

import { Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import { getDate } from '@extensions/utils';

import Roboto from '@assets/roboto/Roboto-Regular.ttf';
import RobotoBold from '@assets/roboto/Roboto-Bold.ttf';

import PdfBody from './pdf-body';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

Font.register({
    family: 'Roboto',
    fonts: [{ src: Roboto }, { src: RobotoBold, fontWeight: 600 }],
    format: 'truetype',
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        color: 'black',
        padding: 15,
        fontSize: 10,
    },

    containerTop: {
        paddingBottom: 30,
        borderBottom: '1px dashed #000000',
    },

    containerBottom: {
        paddingTop: 30,
    },

    row: {
        display: 'flex' /* or inline-flex */,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    header: {
        fontWeight: 'bold',
        fontSize: 12,
        marginTop: 0,
        textAlign: 'center',
    },

    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: 10,
        marginTop: 5,
    },

    journalNumber: {
        fontWeight: 'bold',
    },

    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },

    infoValue: {
        fontWeight: 600,
        fontSize: 12,
    },

    acceptLabel: {
        fontWeight: 600,
    },

    signContainer: {
        display: 'flex' /* or inline-flex */,
        flexDirection: 'row',
    },

    underSign: {
        width: 105,
        borderBottom: '1px solid #000000',
    },

    underComment: {
        width: '100%',
        borderBottom: '1px solid #000000',
    },

    temperature: {
        marginTop: 2,
        marginBottom: 5,
        fontSize: 9,
    },

    cutLine: {
        marginTop: 10,
        marginBottom: 10,
        border: '1px dashed #000000',
    },
});

const CertificatePdf = ({ ...props }) => {
    const { printData } = props;

    let journal = printData?.journal;
    let truck = printData?.truck;

    return (
        <Document>
            {printData?.headers.map((h) => {
                return (
                    <Page key={uuid()} size='A4' style={styles.page} orientation={'portrait'} wrap={true}>
                        <View style={styles.containerTop}>
                            <View style={styles.row}>
                                <View style={styles.infoContainer}>
                                    <Text>Гос номер А/М: </Text>
                                    <Text style={styles.infoValue}>{truck?.number}</Text>
                                </View>
                                <View style={styles.infoContainer}>
                                    <Text>Дата: </Text>
                                    <Text style={styles.infoValue}>{getDate(journal?.completedOn)}</Text>
                                </View>
                            </View>
                            <View style={styles.infoContainer}>
                                <Text>Тел. водителя: </Text>
                                <Text style={styles.infoValue}>{truck?.driverPhone}</Text>
                            </View>

                            <View style={styles.header}>
                                <Text>FRESHLINE TRUCK</Text>
                            </View>
                            <View style={styles.titleContainer}>
                                <Text>Акт приема передачи груза № </Text>
                                <Text style={styles.journalNumber}>"{journal?.journalNumber}"</Text>
                            </View>
                            <View>
                                <PdfBody header={h} />
                            </View>
                            <View style={styles.row}>
                                <View style={styles.truckNumber}>
                                    <Text>МЕНЕДЖЕР груз сдал</Text>
                                </View>
                                <View>
                                    <Text>Водитель груз принял</Text>
                                    <Text style={styles.acceptLabel}>Претензий по количеству и качеству не имею</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View>
                                    <View style={styles.signContainer}>
                                        <Text style={styles.underSign}></Text>
                                        <Text>/</Text>
                                        <Text style={styles.underSign}></Text>
                                    </View>
                                    <View style={styles.temperature}>
                                        <Text>Температура при получении</Text>
                                    </View>
                                </View>
                                <View>
                                    <View style={styles.signContainer}>
                                        <Text style={styles.underSign}></Text>
                                        <Text>/</Text>
                                        <Text style={styles.underSign}></Text>
                                    </View>
                                    <View style={styles.temperature}></View>
                                </View>
                            </View>
                            <View>
                                <Text>Целостность коробки нарушена</Text>
                                <View style={styles.row}>
                                    <Text>Заметки, комментарии:</Text>
                                </View>
                                <Text style={styles.underComment}></Text>

                                <View style={styles.underComment}>
                                    <Text> </Text>
                                </View>
                                <View style={styles.underComment}>
                                    <Text> </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.containerBottom}>
                            <View style={styles.row}>
                                <View style={styles.infoContainer}>
                                    <Text>Гос номер А/М: </Text>
                                    <Text style={styles.infoValue}>{truck?.number}</Text>
                                </View>
                                <View style={styles.infoContainer}>
                                    <Text>Дата: </Text>
                                    <Text style={styles.infoValue}>{getDate(journal?.completedOn)}</Text>
                                </View>
                            </View>
                            <View style={styles.infoContainer}>
                                <Text>Тел. водителя: </Text>
                                <Text style={styles.infoValue}>{truck?.driverPhone}</Text>
                            </View>

                            <View style={styles.header}>
                                <Text>FRESHLINE TRUCK</Text>
                            </View>
                            <View style={styles.titleContainer}>
                                <Text>Акт приема передачи груза № </Text>
                                <Text style={styles.journalNumber}>"{journal?.journalNumber}"</Text>
                            </View>
                            <View>
                                <PdfBody header={h} />
                            </View>
                            <View style={styles.row}>
                                <View style={styles.truckNumber}>
                                    <Text>КЛИЕНТ груз принял</Text>
                                    <Text style={styles.acceptLabel}>Претензий по количеству и качеству не имею</Text>
                                </View>
                                <View>
                                    <Text style={{ marginRight: 125 }}>Водитель груз сдал</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View>
                                    <View style={styles.signContainer}>
                                        <Text style={styles.underSign}></Text>
                                        <Text>/</Text>
                                        <Text style={styles.underSign}></Text>
                                    </View>
                                    <View style={styles.temperature}>
                                        <Text>Температура при получении</Text>
                                    </View>
                                </View>
                                <View>
                                    <View style={styles.signContainer}>
                                        <Text style={styles.underSign}></Text>
                                        <Text>/</Text>
                                        <Text style={styles.underSign}></Text>
                                    </View>
                                    <View style={styles.temperature}></View>
                                </View>
                            </View>
                            <View>
                                <Text>Целостность коробки нарушена</Text>
                                <View style={styles.row}>
                                    <Text>Заметки, комментарии:</Text>
                                </View>
                                <Text style={styles.underComment}></Text>

                                <View style={styles.underComment}>
                                    <Text> </Text>
                                </View>
                                <View style={styles.underComment}>
                                    <Text> </Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                );
            })}
        </Document>
    );
};
export default CertificatePdf;
