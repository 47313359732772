import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Button, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import './form-header.css';

interface IFormHeaderProps {
    title: any;
    hideGoBack?: boolean;
    container?: any;
}

const FormHeader = (props: IFormHeaderProps) => {
    const { Title } = Typography;

    const { title, hideGoBack, container } = props;

    const navigate = useNavigate();

    return (
        <div className='form-header-container'>
            <Flex align='center' gap='large' justify='space-between'>
                <Flex align='center' gap='small'>
                    {!hideGoBack && (
                        <Button
                            style={{ paddingLeft: 0 }}
                            size='large'
                            type='link'
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <LeftOutlined style={{ fontSize: 24 }} />
                        </Button>
                    )}
                    <Title level={3} style={{ margin: 0 }}>
                        {title}
                    </Title>
                </Flex>
                {container}
            </Flex>
        </div>
    );
};

export default FormHeader;
