import React from 'react';
import _ from 'lodash';

import './security-restriction-control.css';

interface ISecurityRestrictionProps {
    message: string;
    permissionLabel: string | string[] | undefined;
}

const SecurityRestrictionControl = (props: ISecurityRestrictionProps) => {
    const { message, permissionLabel } = props;

    let permissions: string | undefined = _.isArray(permissionLabel) ? permissionLabel.join(', ') : permissionLabel;

    return (
        <div className='popup-notification'>
            <div className='message'>{`${message} "${permissions}"`}</div>
            <div className='message'>Пожалуйста, обратитесь к администратору ресурса.</div>
        </div>
    );
};

export default SecurityRestrictionControl;
