import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import { Table, Input, Row, Col, notification, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, FilterFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import Files from '@components/documents/files';

import { exception } from '@extensions/notification';
import { delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IConsignee } from '@entities/consignee';
import { IConsigneeFilter } from '@entities/consignee-filter';

import { serverFetch } from '@src/core/server';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Documents';

const Documents = () => {
    const initFilter: IConsigneeFilter = {
        isArchived: false,
        isLockedOnly: false,
        //showBills = true
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const filter = useAppSelector<IConsigneeFilter>((s) => s.filters[filterContext]);

    const [consignees, setConsignees] = useState<Array<IConsignee>>([]);
    const [currentConsignee, setCurrentConsignee] = useState<IConsignee>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setUserFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setConsignees([]);

        setLoading(true);

        serverFetch('consignees', { method: 'GET', queryParams: filter })
            .then((data) => {
                setConsignees(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения грузополучтателей', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        setCurrentConsignee(undefined);
        setSelectedIds([]);

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderUserToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setUserFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderUserFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <InputNumber
                            key='code'
                            placeholder='ID клиента'
                            value={filter?.code}
                            style={{ width: 130 }}
                            onChange={(value) => {
                                d(setFilter({ ...filter, code: value }, filterContext));
                            }}
                        />,
                        <Input
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = consignees.find((o) => o.id == selectedRowKeys[0]);
            setCurrentConsignee(entity);
        } else {
            setCurrentConsignee(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignee> = [
            {
                title: 'ID',
                align: 'center',
                width: 60,
                dataIndex: 'code',
            },
            {
                title: 'Маркировка',
                dataIndex: 'allMarkings',
            },
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={consignees}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height ?? 0 }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <div ref={containerRef} style={{ height: `calc(100vh - 190px - ${filterHeight}px)` }}>
                        {renderUserToolbar()}
                        {renderUserFilter()}
                        {renderTable()}
                    </div>
                </Col>
                <Col offset={1} span={14}>
                    <Files consignee={currentConsignee} />
                </Col>
            </Row>

            {contextHolder}
        </>
    );
};

export default Documents;
