import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Modal, Table, Button, Col, Row, Input, Tooltip, Form, Flex, Popconfirm, notification } from 'antd';
import { LoadingOutlined, ReloadOutlined, PrinterOutlined, SwapOutlined, DeleteFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import PrintProcessing from '@controls/print-processing';
import TransferBoxesModal from '@controls/transfer-boxes-modal/transfer-boxes-modal';

import { exception, securityRestriction } from '@extensions/notification';
import { getDateTimeLocal } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import BarcodePdf from '@print-forms/barcode-pdf';

import { IConsignmentLabel } from '@entities/consignment-label';
import { IBox } from '@entities/box';
import { IBoxGroup } from '@entities/box-group';
import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';

dayjs.extend(utc);

interface IDeleteBoxParams {
    id: string;
    reason: string;
}

const Remaining = () => {
    const navigate = useNavigate();

    const { boxGroupId } = useParams();

    const { TextArea } = Input;

    const [deleteBoxForm] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const d = useAppDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [boxGroup, setBoxGroup] = useState<IBoxGroup>();
    const [boxes, setBoxes] = useState<Array<IBox>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);

    const [printData, setPrintData] = useState<IConsignmentLabel | undefined>();
    const [startPrint, setStartPrint] = useState<boolean>(false);

    const [openDeleteBoxModal, setOpenDeleteBoxModal] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        serverFetch(`warehouse/boxgroup/${boxGroupId}`, { method: 'GET' })
            .then((data) => {
                setBoxGroup(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения партии', ex, () => d(userLoaded(undefined)));
            });

        onLoadBoxes();
    }, []);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const onLoadBoxes = async () => {
        setLoading(true);

        serverFetch(`remainings/${boxGroupId}/boxes`, { method: 'GET' })
            .then((data: Array<IBox>) => {
                if (data.length <= 0) navigate(-1);

                setBoxes(data);

                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const getPrintData = (ids: Array<string>) => {
        serverFetch(`warehouse/box/print`, { method: 'POST', bodyData: ids })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteBox = (data: IDeleteBoxParams) => {
        setLoading(true);

        serverFetch(`warehouse/box/${data.id}`, { method: 'DELETE', bodyData: data.reason })
            .then(() => {
                deleteBoxForm.resetFields();
                setOpenDeleteBoxModal(false);
                onLoadBoxes();
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            onLoadBoxes();
                        },
                    },
                    {
                        label: 'Перестановка',
                        key: 'transfer',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <SwapOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.TransferBoxes)) {
                                securityRestriction(api, [Permission.TransferBoxes]);
                                return;
                            }

                            setOpenTransferModal(true);
                        },
                    },
                    {
                        label: 'Печать этикеток',
                        key: 'print',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            getPrintData(selectedRowKeys as Array<string>);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const renderTable = () => {
        const columns: ColumnsType<IBox> = [
            {
                title: ' ',
                key: 'boxAction',
                align: 'center',
                width: 80,
                render: (_: any, record: IBox) => {
                    return (
                        <Flex gap='small'>
                            <Popconfirm
                                title={`Удалить коробку "${record.number}"?`}
                                onConfirm={() => {
                                    if (!hasPermission(userSession.permissions, Permission.DeleteRemainingBox)) {
                                        securityRestriction(api, [Permission.DeleteRemainingBox]);
                                        return;
                                    }

                                    deleteBoxForm.setFieldValue('id', record.id);
                                    setOpenDeleteBoxModal(true);
                                }}
                            >
                                <Button icon={<DeleteFilled />} size={'small'} />
                            </Popconfirm>

                            <Tooltip placement='topRight' title='Печать этикетки'>
                                <Button
                                    icon={<PrinterOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (
                                            !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                            !hasPermission(userSession.permissions, Permission.PrintDocuments)
                                        ) {
                                            securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                            return;
                                        }

                                        getPrintData([record.id]);
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 200,
                onCell: (record) => ({
                    style: {
                        color: !record.acceptedOn ? 'var(--main-red)' : '',
                    },
                }),
            },
            {
                title: 'Страна',
                render: () => {
                    return boxGroup?.countryName;
                },
                width: 150,
            },
            {
                title: 'Дата приемки',
                align: 'center',
                width: 120,
                render: (_: any, record: IBox) => {
                    return getDateTimeLocal(record.acceptedOn);
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 400,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={boxes}
                pagination={false}
                scroll={{ y: `calc(100vh - 190px)` }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                    getCheckboxProps: (record) => ({
                        style: { display: !record.acceptedOn ? 'none' : '' },
                    }),
                }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <FormHeader title={`Партия`} />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={boxGroup?.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={boxGroup?.markingCode} />
                                </Form.Item>
                                <Form.Item label='AWB'>
                                    <Input disabled value={boxGroup?.awbNumber} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={boxGroup?.comment} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={14}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {renderTable()}
                    </div>
                </Col>
            </Row>

            {boxGroupId && openTransferModal && (
                <TransferBoxesModal
                    boxGroupId={boxGroupId}
                    onSave={() => {
                        onLoadBoxes();
                        setOpenTransferModal(false);
                    }}
                    onCancel={() => setOpenTransferModal(false)}
                    api={api}
                    splitConsignment={false}
                    boxIds={selectedRowKeys as Array<string>}
                />
            )}

            {startPrint && <PrintProcessing />}

            <Modal
                width={400}
                title='Причина удаление коробки'
                open={openDeleteBoxModal}
                okText='ОК'
                closable={false}
                onOk={() => {
                    deleteBoxForm.submit();
                }}
                onCancel={() => {
                    deleteBoxForm.resetFields();
                    setOpenDeleteBoxModal(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={deleteBoxForm} onFinish={onDeleteBox}>
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='reason' required rules={[{ required: true, message: 'Укажите причину удаления' }]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Remaining;
