import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@store/hooks';
import BalanceDetailsControl from '@controls/balance-details/balance-details';

import { IUserSession } from '@entities/user-session';
import { Permission, hasPermission } from '@enums/permission';

const BalanceDetails = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { userId } = useParams();

    return (
        <BalanceDetailsControl
            userId={userId || ''}
            isViewMode={!hasPermission(userSession.permissions, Permission.ManageBalance)}
            showExchangeRateInfo={true}
        />
    );
};

export default BalanceDetails;
